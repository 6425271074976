import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';

import { UtilHelper } from './utils.helper';

export class HttpHelper {

	private httpClient: HttpClient;
	private httpNative: HTTP;

	private readonly TIMEOUT: number = 30;

	constructor(httpClient: HttpClient, httpNative: HTTP) {
		this.httpClient = httpClient;
		this.httpNative = httpNative;

		if (UtilHelper.isNativeApp()) {
			this.configNativeClient();
		}
	}

	get(path: string, headers: any, forceUseHttpClient?: boolean): Promise<any> {
		if (forceUseHttpClient || !UtilHelper.isNativeApp()) {
			return this.httpClient.get(path, { headers: headers }).toPromise<any>();
		} else {
			this.setDataSerializer(headers);
			return this.httpNative.get(path, null, headers);
		}
	}

	post(path: string, body: any, headers: any): Promise<any> {
		if (UtilHelper.isNativeApp()) {
			this.setDataSerializer(headers);
			return this.httpNative.post(path, body, headers);
		} else {
			body = this.browserSerializeFormUrlEncoded(headers, body);
			return this.httpClient.post(path, body, { headers: headers, observe: 'response' }).toPromise<any>();
		}
	}

	put(path: string, body: any, headers: any): Promise<any> {
		if (UtilHelper.isNativeApp()) {
			this.setDataSerializer(headers);
			return this.httpNative.put(path, body, headers);
		} else {
			body = this.browserSerializeFormUrlEncoded(headers, body);
			return this.httpClient.put(path, body, { headers: headers }).toPromise<any>();
		}
	}

	patch(path: string, body: any, headers: any): Promise<any> {
		if (UtilHelper.isNativeApp()) {
			this.setDataSerializer(headers);
			return this.httpNative.patch(path, body, headers);
		} else {
			body = this.browserSerializeFormUrlEncoded(headers, body);
			return this.httpClient.patch(path, body, { headers: headers }).toPromise<any>();
		}
	}

	delete(path: string, headers: any): Promise<any> {
		if (UtilHelper.isNativeApp()) {
			this.setDataSerializer(headers);
			return this.httpNative.delete(path, null, headers);
		} else {
			return this.httpClient.delete(path, { headers: headers }).toPromise<any>();
		}
	}

	private configNativeClient(): void {
		if (UtilHelper.isNativeApp()) {
			this.httpNative.setRequestTimeout(this.TIMEOUT);
			// https://github.com/silkimen/cordova-plugin-advanced-http#setservertrustmode
			// environment.production ? "default" : "nocheck"
			this.httpNative.setServerTrustMode("nocheck").then(() => {
				console.log("SSL CERT OK!!!");
			}).catch((e) => {
				console.error("Error SSL", e);
			});
		}
	}

	private setDataSerializer(headers: any): void {
		try {
			if (headers) {
				const contentType: any = headers['Content-Type'];
				if (contentType && contentType.indexOf("application/x-www-form-urlencoded") >= 0) {
					this.httpNative.setDataSerializer("urlencoded");
				} else {
					this.httpNative.setDataSerializer("json");
				}
			} else {
				this.httpNative.setDataSerializer("json");
			}
		} catch (e) { }
	}

	private browserSerializeFormUrlEncoded(headers: HttpHeaders, body?: any): any {
		if (!body || !headers) return body;
		try {
			const contentType: string = headers.get('Content-Type');
			if (contentType && contentType.indexOf("application/x-www-form-urlencoded") >= 0) {
				const params: URLSearchParams = new URLSearchParams();
				for (const key in body) {
					if (body[key] != null) {
						params.append(key, body[key]);
					}
				}
				return params.toString();
			}
		} catch (e) {
			console.error(e);
		}
		return body;
	}
}
