import { DecimalPipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localePtBR from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Market } from '@ionic-native/market/ngx';
import { Network } from '@ionic-native/network/ngx';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, isPlatform } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicImageLoader } from 'ionic-image-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_CONFIG } from './commom/constants';
import { ModalHelperPage } from './components/modal-helper/modal-helper.page';
import { MaintenancePageModule } from './pages/maintenance/maintenance.module';
import { ModalErrorQrcodePageModule } from './pages/modal-error-qrcode/modal-error-qrcode.module';
import { ModalInfoPageModule } from './pages/modal-info/modal-info.module';
import { ModalLocationPermissionPageModule } from './pages/modal-location-permission/modal-location-permission.module';
import { ModalNotificationPermissionPageModule } from './pages/modal-notification-permission/modal-notification-permission.module';
import { ModalSuccessfulPageModule } from './pages/modal-successful/modal-successful.module';


//https://angular.io/guide/i18n#i18n-pipes
registerLocaleData(localePtBR);
registerLocaleData(localeEs);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json?version=' + APP_CONFIG.VERSION);
}

@NgModule({
	declarations: [
		AppComponent,
		ModalHelperPage
	],
	entryComponents: [],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		IonicModule.forRoot({
			backButtonText: '',
			backButtonDefaultHref: 'tabs/home'
		}),
		IonicStorageModule.forRoot({
			name: 'app_consumer_DB'
		}),
		IonicImageLoader.forRoot(),
		MaintenancePageModule,
		ModalInfoPageModule,
		ModalErrorQrcodePageModule,
		ModalLocationPermissionPageModule,
		ModalNotificationPermissionPageModule,
		ModalSuccessfulPageModule,
		// Para poder testar a api localmente, foi preciso deixar para ativar o service worker apenas se for ambiente de produção
		// && environment.production
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: !isPlatform("cordova") && location.hostname != "localhost" }), 
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
	],
	providers: [
		AppVersion,
		BarcodeScanner,
		Device,
		Diagnostic,
		DecimalPipe,
		Deeplinks,
		Facebook,
		Geolocation,
		GooglePlus,
		HTTP,
		LocationAccuracy,
		Market,
		Network,
		SafariViewController,
		SplashScreen,
		StatusBar,
		LaunchNavigator,
		WebView,
		// { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{
			provide: LOCALE_ID,
			useFactory: (translate: TranslateService) => {
				const lang = localStorage.getItem("prefferedLanguage") || translate.getBrowserLang() || "pt";
				return lang;
		  	},
		  	deps: [TranslateService]
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
