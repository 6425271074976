import { Component, Injector, OnInit } from '@angular/core';

import { NavParams } from '@ionic/angular';
import { ModalInfo } from '../../models/modal-info.model';
import { ModalBasePage } from '../base/modal-base.page';

@Component({
	selector: 'app-modal-info',
	templateUrl: 'modal-info.page.html',
	styleUrls: ['modal-info.page.scss'],
})
export class ModalInfoPage extends ModalBasePage implements OnInit {

	info: ModalInfo;	

	constructor(
		private navParams: NavParams,
		private injector: Injector
	) {
		super(injector);
	}

	ngOnInit(): void {
		this.info = this.navParams.get("info");
	}

	ok(): void {
		this.modalCtrl.dismiss(true);
	}
}
