import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: '', redirectTo: 'tabs/home', pathMatch: 'full'},
	{ path: '', loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule) },
	{ path: 'login', loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule)},
	{ path: 'check-phone', loadChildren: () => import('./pages/user/phone/check-phone/check-phone.module').then((m) => m.CheckPhonePageModule)},
	{ path: 'user', loadChildren: () => import('./pages/user/user.module').then((m) => m.UserPageModule)},
	{ path: 'address', loadChildren: () => import('./pages/user/address/address.module').then((m) => m.AddressPageModule)},
	{ path: 'maps', loadChildren: () => import('./pages/maps/maps.module').then((m) => m.MapsPageModule)},

	{ path: 'local/:uuid', loadChildren: () => import('./pages/place/place.module').then((m) => m.PlacePageModule)},
	{ path: 'local/:name/:uuid', loadChildren: () => import('./pages/place/place.module').then((m) => m.PlacePageModule)},

	{ path: 'store/:tableCode', loadChildren: () => import('./pages/store/store.module').then((m) => m.StorePageModule)},
	{ path: 'store/:name/:uuid', loadChildren: () => import('./pages/store/store.module').then((m) => m.StorePageModule)},

	// ------ CONFIGS OWN APP ------
	// { path: 'menu', loadChildren: () => import('./pages/store/store.module').then((m) => m.StorePageModule)},
	// { path: 'details', loadChildren: () => import('./pages/store/store-detail/store-detail.module').then((m) => m.StoreDetailPageModule)},
	// { path: 'product/:productId', loadChildren: () => import('./pages/store/order-config/product/product.module').then((m) => m.ProductPageModule)},
	// { path: 'reservation', loadChildren: () => import('./pages/store/store-reservation/store-reservation.module').then( m => m.StoreReservationPageModule) },

	// ------ FIM OWN APP --------
	{ path: 'summary', loadChildren: () => import('./pages/store/order-summary/order-summary.module').then((m) => m.OrderSummaryPageModule)},
	{ path: 'payment', loadChildren: () => import('./pages/store/payment/payment.module').then((m) => m.PaymentModule)},
	{ path: 'payment-methods', loadChildren: () => import('./pages/payment-methods/payment-methods.module').then((m) => m.PaymentMethodsPageModule)},
	{ path: 'card', loadChildren: () => import('./pages/payment-methods/card/card.module').then((m) => m.CardPageModule)},
	{ path: 'select-address', loadChildren: () => import('./pages/store/payment/select-address/select-address.module').then((m) => m.SelectAddressModule)},
	{ path: 'ratings', loadChildren: () => import('./pages/store/ratings/ratings.module').then((m) => m.RatingsPageModule)},

	{ path: 'order/:orderId', loadChildren: () => import('./pages/orders/details/order-details.module').then((m) => m.OrderDetailsPageModule)},
	{ path: 'order-rating', loadChildren: () => import('./pages/orders/rating/order-rating/order-rating.module').then((m) => m.OrderRatingPageModule)},
	{ path: 'rate-order', loadChildren: () => import('./pages/orders/rating/rate-order/rate-order.module').then((m) => m.RateOrderPageModule)},

	{ path: 'reservation/:id', loadChildren: () => import('./pages/reservations/reservation-details/reservation-details.module').then( m => m.ReservationDetailsPageModule)},
	{ path: 'reservation/:public/:id', loadChildren: () => import('./pages/reservations/reservation-details/reservation-details.module').then( m => m.ReservationDetailsPageModule)},
	
	{ path: 'queue/:public/:uuid', loadChildren: () => import('./pages/queue/queue-details/queue-details.module').then( m => m.QueueDetailsPageModule)},
	{ path: 'queue/:uuid', loadChildren: () => import('./pages/queue/queue-details/queue-details.module').then( m => m.QueueDetailsPageModule)},

	{ path: 'coupons', loadChildren: () => import('./pages/coupons/coupons.module').then((m) => m.CouponsPageModule)},
	{ path: 'show-photo', loadChildren: () => import('./pages/show-photo/show-photo.module').then((m) => m.ShowPhotoPageModule)},
	{ path: 'suggestion', loadChildren: () => import('./pages/store-suggestion/store-suggestion.module').then((m) => m.StoreSuggestionPageModule)},
	{ path: 'about', loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule)},
	{ path: 'table/:tableCode', loadChildren: () => import('./pages/table-link/table-link.module').then((m) => m.TableLinkPageModule)},

	// Modal menu (from store page)
	{ path: 'orders', loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule)}, 
	{ path: 'reservations', loadChildren: () => import('./pages/reservations/reservations.module').then( m => m.ReservationsPageModule)},

	{ path: '**', redirectTo: 'tabs/home'}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
