import { UtilHelper } from "../helpers/utils.helper";

export class Address {
	id: number;
	userId: number;
	street: string;
	number: string;
	complement: string;
	neighborhood: string;
	zipCode: string;
	state: string;
	city: string;
	country: string;
	description: string;
	defaultAddress: boolean;
	lat?: number;
	lon?: number;

	// Usado no drive-in
	car?: string;

	displayShortDescription: string;

	constructor(obj: any = { }) {
		Object.assign(this, obj);

		if (!this.displayShortDescription) {
			this.displayShortDescription = this.getStreetFormattedAddress();
		}
		if (!this.country) {
			this.country = "BR";
		}
	}

	getFullFormattedAddress(): string {
		let address: string = "";
		if (this.street) {
			address += this.street;
		}
		if (this.number) {
			address += ", " + this.number + " - ";
		}
		if (this.complement) {
			address += this.complement;
		}
		if (this.neighborhood) {
			address += (this.complement ? " - " : "") + this.neighborhood;
		}
		if (this.city) {
			address += ", " + this.city;
		}
		if (this.state) {
			address += " - " + this.state;
		}
		return address;
	}

	getStreetFormattedAddress(): string {
		let address: string = "";
		if (this.street) {
			address += this.street;
		}
		if (this.number) {
			address += ", " + this.number;
		}
		return address;
	}

	// Ap 2 - Centro, Curitiba - PR
	getComplementFormattedAddress(): string {
		let address: string = "";
		if (this.complement) {
			address += this.complement;
		}
		if (this.neighborhood) {
			address += (address ? " - " : " ") + this.neighborhood;
		}
		if (this.city) {
			address += ", " + this.city;
		}
		if (this.state) {
			address += " - " + this.state;
		}
		return address;
	}

	toBillingModel(): Address {
		return {
			street: this.street,
			number: this.number,
			complement: this.complement,
			zipCode: UtilHelper.unmask(this.zipCode),
			state: this.state,
			city: this.city,
			country: this.country || "BR"
		} as Address; 
	}
}
