import { Injectable } from "@angular/core";

declare var placeId;

@Injectable({
	providedIn: 'root'
})
export class AdsService {

	initThirky(): void {
		try {
			const script: any = document.createElement('script');
			script.innerHTML = `
				window.placeId = "";
				var groupId = "8198365", highlightSpace = "highlightSpace", listSpace = "listSpace";
				!function(){var t=document.createElement("script");
				t.src="https://integration.thirky.com/universal-v1.js",t.async=!0,document.getElementsByTagName("script")[0].parentNode.insertBefore(t,null)}();
			`;
			document.head.appendChild(script);
		} catch (e) {
			console.error(e);
		}
	}

	setPlaceId(id: string): void {
		placeId = id;
	}
}